import * as React from 'react'
import cn from 'classnames'
import styles from './Link.module.scss'

export type LinkVariant = 'brand' | 'success' | 'warning' | 'info' | 'danger'

export type LinkProps = {
  /**
   * Content to put inside the link
   * @example <Link>Click me!</Link>
   */
  children: React.ReactNode
  /**
   * The variant to use for the link, this will determine the text colour, success is green, info is blue, warning is amber and danger is red.
   * @example <Link variant="success" />
   */
  variant?: LinkVariant
  /**
   * Whether or not the link should be underlined, default is true
   * @example <Link underline={false}>Click me!</Link>
   */
  underline?: boolean
  /**
   * Whether or not to apply external link props such as `target="_blank"` and `rel="noopener noreferrer"`
   */
  isExternal?: boolean
  showArrow?: boolean
  arrowLocation?: 'left' | 'right'
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'children'>

export const Link: React.VFC<LinkProps> = (props) => {
  const {
    children,
    className,
    variant,
    underline = true,
    isExternal,
    showArrow = false,
    arrowLocation = 'left',
    ...restProps
  } = props

  const externalProps = { target: '_blank', rel: 'noopener noreferrer' }

  const leftArrowContent =
    showArrow && arrowLocation === 'left' ? <span aria-hidden>← </span> : null
  const rightArrowContent =
    showArrow && arrowLocation === 'right' ? <span aria-hidden> →</span> : null

  return (
    <a
      className={cn(
        styles.link,
        variant && styles[variant],
        {
          [styles.underline]: underline,
        },
        className
      )}
      {...(isExternal && externalProps)}
      {...restProps}
    >
      {leftArrowContent}
      {children}
      {rightArrowContent}
    </a>
  )
}
